<script>
  import { onMount } from "svelte";
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import IconButton from '@smui/icon-button';
  import { Label } from '@smui/button';
  import CircularProgress from "@smui/circular-progress";
  import Card from "@smui/card";
  import Dialog, { Title, Content, Actions, InitialFocus } from '@smui/dialog';
  import {
    getIntegrationDetails,
    updateIntegration,
    externalIntegrationAuthorization
  } from "src/services/integrationsEngineApi/integrations";
  import {
    getIntegrationUI
  } from "src/services/integrationsEngineApi/integrationsUI";
  import { getLocations } from "src/services/api/locations";
  import LocationMappings from "./locationMappings.svelte";
  import Snackbar from "@smui/snackbar";
  import Textfield from "@smui/textfield";

  export let partnerRef;
  export let merchantId;

  let integrationUIDetails = getIntegrationUI(partnerRef)
  let extraFieldsValues = []
  if(integrationUIDetails.extraFields) {
    Object.entries(integrationUIDetails.extraFields).forEach(element => {
      const [key, label] = element
      extraFieldsValues[key] = ''
    });
  }

  let isLoading = true;
  let isActive = false;
  let isNew = false;
  let webhookUrl = '';
  let isOAuthRevokeDialogOpen = false;
  let integrationLocations;
  let locations;
  let snackBar;
  let snackBarClass;
  let snackBarMessage;

  onMount(() => {
    loadAllData();
  });

  async function loadAllData() {
    // Retrieve Tattle Locations
    isLoading = true
    const result = await getLocations({
      active: 1,
      expand: "address",
      merchants_id: merchantId,
      page: 1,
      size: -1,
    });
    locations = result

    await retrieveIntegration();
    isLoading = false;
  }

  function retrieveIntegration() {
    return getIntegrationDetails(partnerRef, merchantId).then(result => {
      let currentIntegration = result
      isActive = currentIntegration.isActive;
      isNew = currentIntegration.status == 404;
      webhookUrl = currentIntegration.webhookUrl;

      integrationLocations = [];
      currentIntegration.locations.forEach(item => {
        let tattleLocation = locations.find(x => x.id == item.locationId)
        integrationLocations.push({
          integrationLocationLabel: item[integrationUIDetails.integrationLocationName],
          integrationLocationId: item[integrationUIDetails.integrationLocationId],
          isActive: item.isActive ? true : false,
          tattleLocationId: tattleLocation?.id,
          tattleLocationLabel: tattleLocation?.label,
          tattleLocationPhone: tattleLocation?.phone_number
        })
      });
    }).catch(() => {
      isActive = false;
    })
  }

  function callUpdateIntegration(status) {
    updateIntegration(isNew, partnerRef, merchantId, status, extraFieldsValues).then(async statusCode => {
      if (statusCode.toString().startsWith('2')) {
        await loadAllData()
        showSnackbarSuccess(status)
        
        if(integrationUIDetails?.refreshSideMenuOnUpdate) {
          // Dispatch event to notify other components that integration has been updated
          const event = new CustomEvent('IntegrationUpdatedEvent', { 
            bubbles: true,
            detail: { 
              partnerRef: partnerRef,
              isActive: status 
            }});
          document.dispatchEvent(event);
        }
      }
      else
        showSnackbarError();
    }).catch(() => {
      showSnackbarError();
    })
  }

  function callExternalIntegrationAuthorization() {
    externalIntegrationAuthorization(partnerRef, merchantId).then(result => {
        window.open(result, '_blank')
      }).catch(() => {
        showSnackbarError('Error encountered while retrieving authorization');
      })
  }

  function copyWebhookToClipboard() {
    navigator.clipboard.writeText(webhookUrl);
  }

  function showSnackbarSuccess(create = false) {
    snackBarClass = "integrationSuccessSurface";
    snackBarMessage = "Integration successfully deactivated!";
    if(create) {
      snackBarMessage = "Integration successfully created!";
    }
    snackBar.open();
  }

  function showSnackbarError(message = 'Error encountered while adding Integration') {
    snackBarClass = "integrationErrorSurface";
    snackBarMessage = message;
    snackBar.open();
  }

  function showFields()
  {
    return isActive ? integrationUIDetails.extraFields && !integrationUIDetails?.hideFieldsAfterSetup : integrationUIDetails.extraFields;
  }
</script>

<h1 data-cy="integrations-generic-heading">{integrationUIDetails.label}</h1>
<hr/>

{#if isLoading}
  <CircularProgress
    style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px; left: 50%;"
    indeterminate
  />
{:else}
  <div class="m-t-lg">
    <p data-cy="integrations-generic-activation-status-label">
      This integration is
      {#if isActive}
        <strong data-cy="integrations-generic-activation-status-value" class="color-success">active</strong>.
      {:else}
        <strong data-cy="integrations-generic-activation-status-value" class="color-danger">inactive</strong>.
        <p data-cy="integrations-generic-activate-text" class="m-t-lg" style="display: flex; align-items: baseline;">
          To activate your {integrationUIDetails.label} integration,
          <Button autoId="integrations-generic-activate-link" on:click={() => callUpdateIntegration(true) }>
            <Label style="font-size: 14px; min-width: 0">Click here</Label>
          </Button>
        </p>
      {/if}
    </p>

    {#if isActive}
      <div class="m-t-lg">
        <div class="inline-block">
          <Button autoId="integrations-generic-revoke-access-link" on:click={() => isOAuthRevokeDialogOpen = true}>
            <Label>Revoke Access</Label>
          </Button>
        </div>
        {#if integrationUIDetails.externalStep?.required}
          <div class="inline-block">
            <Button autoId="integrations-generic-external-step-link" on:click={() => callExternalIntegrationAuthorization() }>
              <Label>{ integrationUIDetails.externalStep?.title }</Label>
            </Button>
          </div>
        {/if}
      </div>
      {#if webhookUrl}
        <div class="m-t-lg">
          <Card data-cy="integrations-generic-webhook-url-card" variant="outlined" padded class="p-a-md">
            <p class="m-a-0">
              <strong data-cy="integrations-generic-webhook-url-label">Webhook URL</strong>
            </p>
            <div data-cy="integrations-generic-webhook-url-text" class="webhook__container">
              <IconButton data-cy="integrations-generic-webhook-url-copy-button" class="material-icons" on:click={() => copyWebhookToClipboard()}>content_copy</IconButton>
              { webhookUrl }
            </div>
          </Card>
        </div>
      {/if}
    {/if}
    {#if showFields() }
      <div class="m-t-lg">
        {#each Object.entries(integrationUIDetails.extraFields) as [key, label]}
          <Textfield
            data-cy="integrations-generic-input-label"
            input$data-cy="integrations-generic-input-text"
            variant="outlined"
            bind:value={extraFieldsValues[key]}
            label={label}
            class="searchBox"
          />
        {/each}
      </div>
    {/if}
  </div>

  {#if isActive}
    <LocationMappings locations={integrationLocations} hideLastSynced showPhone={integrationUIDetails.phone?.show} tooltipPhone={integrationUIDetails.phone?.tooltip} />
  {/if}

{/if}

<Snackbar
  bind:this={snackBar}
  surface$data-cy="integrations-snackbar"
  surface$class={snackBarClass}
>
  <Label>{snackBarMessage}</Label>
</Snackbar>

<Dialog
  bind:open={isOAuthRevokeDialogOpen}
  aria-labelledby="generic-revoke-access-dialog"
  aria-describedby="generic-revoke-access-dialog-content"
>
  <Title data-cy="generic-revoke-access-dialog-title" id="generic-revoke-access-dialog-title">Confirm</Title>
  <Content data-cy="generic-revoke-access-dialog-content" id="generic-revoke-access-dialog-content">
    This will revoke Tattle's access to your {integrationUIDetails.label} account. Do you want to continue?
  </Content>
  <Actions>
    <Button autoId="generic-revoke-access-dialog-button-continue" on:click={() => callUpdateIntegration(false)}>
      <Label>Continue</Label>
    </Button>
    <Button autoId="generic-revoke-access-dialog-button-cancel" use={[InitialFocus]}>
      <Label>Cancel</Label>
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  :global(.p-a-md) {
    padding: 8px;
  }
  :global(.m-a-0) {
    margin: 0;
  }

  :global(.webhook__container) {
    display: flex;
    align-items: center;
  }

  :global(.integrationSuccessSurface) {
    background-color: var(--tattle-success-color);
  }
  :global(.integrationErrorSurface) {
    background-color: var(--tattle-fail-color);
  }
</style>
